var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg fill=\"none\" viewbox=\"0 0 24 24\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><path d=\"M19.82 8.8s-.15-1.1-.63-1.59c-.6-.64-1.28-.64-1.6-.68-2.24-.17-5.58-.17-5.58-.17H12s-3.37 0-5.6.17c-.3.04-1 .05-1.6.68-.47.5-.63 1.59-.63 1.59S4 10.1 4 11.4v1.21c0 1.3.17 2.58.17 2.58s.15 1.1.63 1.58c.6.65 1.4.62 1.77.69 1.28.12 5.43.17 5.43.17s3.37 0 5.6-.17c.3-.04 1-.04 1.59-.69.47-.49.63-1.58.63-1.58S20 13.9 20 12.6V11.4c0-1.3-.17-2.6-.17-2.6zm-9.48 5.27V9.58l4.33 2.25-4.33 2.24z\" fill=\"currentColor\"/></svg>";
},"useData":true});