import { app } from 'app/app';
import { MasterLayout } from 'layout/master/main';
import { AppController } from 'app/controller';
import { user } from 'app/user';
import { userCompanyFollow } from 'app/userCompanyFollow';
import { MAIL_NEVER, MAIL_WEEKLY } from 'app/mailSubscriptionInterval';
import { TIME_SNACKBAR_MEDIUM } from 'app/timings';
import { ArticleCardSlider } from 'module/articleCardSlider/main';
import { JobsSearchform } from 'module/jobsSearchform/main';
import { JobFieldsListing } from 'module/jobFieldsListing/main';
import { trackGa4 } from 'util/googleAnalytics';
import { params as urlWithParams } from 'util/url';
import { Base64Link } from 'module/base64Link/main';
import { RecentSearchesList } from 'module/recentSearchesList/main';
import { RecentlyViewedJobsSlider } from 'module/recentlyViewedJobsSlider/main';
import { ENDPOINT_JOBS } from 'app/endpoints';
import { InterestingCompanies } from 'module/interestingCompanies/main';
import { BackToTopButton } from 'module/backToTopButton/main';
import { ProposalPillList } from 'module/proposalPillList/main';
import 'module/divider/main';
import 'module/linkList/main';
import './styles.scss';

class IndexIndex extends AppController {
    domBindings() {
        return {
            indexIndex: '.c-indexIndex',
            jobsSearchForm: '.m-jobsSearchform',
            jobFieldsListing: '.m-jobFieldsListing',
            articleCardSlider: '.c-indexIndex__articleCardSlider .m-articleCardSlider',
            companyLogoSlider: '.m-interestingCompanies',
            loginButton: '.m-layoutHeader__user',
            links: ['.m-base64Link'],
            recentSearchesMobile: '.c-indexIndex__recentSearchesList--mobile .m-recentSearchesList',
            recentSearchesDesktop: '.c-indexIndex__recentSearchesList--noMobile .m-recentSearchesList',
            recentlyViewed: '.m-recentlyViewedJobsSlider',
            proposalPillLists: ['.m-proposalPillList'],
            backToTopButton: '.m-backToTopButton',
        };
    }

    subs() {
        this.layout = new MasterLayout(document.documentElement);
        this.jobsSearchForm = new JobsSearchform(this.dom.jobsSearchForm);
        this.articleCardSlider = new ArticleCardSlider(this.dom.articleCardSlider);
        this.companyLogoSlider = new InterestingCompanies(this.dom.companyLogoSlider);
        this.jobFieldsListing = new JobFieldsListing(this.dom.jobFieldsListing);
        this.recentSearchesMobile = new RecentSearchesList(this.dom.recentSearchesMobile);
        this.recentSearchesDesktop = new RecentSearchesList(this.dom.recentSearchesDesktop);
        this.recentlyViewed = new RecentlyViewedJobsSlider(this.dom.recentlyViewed);
        this.backToTopButton = new BackToTopButton(this.dom.backToTopButton);

        this.proposalPillLists = [];
        this.dom.proposalPillLists.forEach((domItem) => {
            this.proposalPillLists.push(new ProposalPillList(domItem));
        });

        this.links = [];
        for (let i = 0; i < this.dom.links.length; i += 1) {
            this.links[i] = new Base64Link(this.dom.links[i]);
        }
    }

    subEvents() {
        if (this.recentSearchesDesktop && this.recentSearchesMobile) {
            this.recentSearchesDesktop.events.on('rerenderRecentSearches', (e) => {
                this.recentSearchesMobile.updateProps(e.arguments[0], true);
                this.recentSearchesDesktop.updateProps(e.arguments[0], true);
            });
            this.recentSearchesMobile.events.on('rerenderRecentSearches', (e) => {
                this.recentSearchesMobile.updateProps(e.arguments[0], true);
                this.recentSearchesDesktop.updateProps(e.arguments[0], true);
            });
        }

        if (this.jobsSearchForm) {
            this.jobsSearchForm.events.on('jobsSearchformIndexSubmit', (e) => {
                window.location.href = this.generateCleanUrl(e.arguments[0]);
                this.sendGa4Tracking(e.arguments[0]);
            });
        }

        if (!user.isLoggedIn()) {
            userCompanyFollow.events.on('onUnauthorized', (followObject) => {
                this.layout.openLoginModal(followObject.arguments[0].companyFollowObject.state, true);
            });
        } else {
            userCompanyFollow.events.on('onAddJob', () => {
                let snackbarText;
                if (this.props.companyAlarmNotificationInterval === MAIL_WEEKLY) {
                    snackbarText = 'Ab sofort erhältst du wöchentlich neue Jobs dieser Firma per E-Mail.';
                } else {
                    snackbarText = 'Ab sofort erhältst du täglich neue Jobs dieser Firma per E-Mail.';
                }

                this.layout.showSnackbar(
                    TIME_SNACKBAR_MEDIUM,
                    false,
                    false,
                    false,
                    false,
                    snackbarText,
                    false,
                    '/settings/notifications#c-notificationIndex-companyalarm',
                    false,
                    'Anpassen',
                );
            });
            userCompanyFollow.events.on('onRemoveJob', () => {
                if (this.props.companyAlarmNotificationInterval !== MAIL_NEVER) {
                    this.layout.showSnackbar(
                        TIME_SNACKBAR_MEDIUM,
                        false,
                        false,
                        false,
                        false,
                        'Du erhältst ab sofort keine Jobs dieser Firma mehr per E-Mail.',
                        true,
                        false,
                        false,
                        false,
                    );
                }
            });
            userCompanyFollow.events.on('onFollowError', (error) => {
                [].forEach.call(error.arguments[0].errors, (err) => {
                    if (err.meta.company_follow_max_limit) {
                        this.layout.showSnackbar(
                            TIME_SNACKBAR_MEDIUM,
                            false,
                            false,
                            false,
                            false,
                            'Oje, du hast das Maximum schon erreicht! Um dieser Firma zu folgen, '
                            + 'entfolge einer anderen Firma.',
                            false,
                            '/companyalarm?openAccordion=true',
                            false,
                            'Zum Firmen-Alarm ',
                        );
                    }
                });
            });
        }
    }

    ready() {
        this.sendAdWordsTracking();
    }

    getPropsFromDom() {
        return {
            companyAlarmNotificationInterval:
                parseInt(this.dom.indexIndex.getAttribute('data-companyalarmnotificationinterval'), 10),
        };
    }

    sendAdWordsTracking() {
        window.dataLayer.push({
            googleAdsData: {
                job_pagetype: 'home',
            },
        });
    }

    generateCleanUrl(searchRequest) {
        const urlParams = {};
        const url = ENDPOINT_JOBS;

        if (searchRequest.keywords !== '') {
            urlParams.keywords = searchRequest.keywords;
        }
        if (searchRequest.locations !== '') {
            urlParams.locations = searchRequest.locations;
        }

        const radius = parseInt(searchRequest.radius, 10);
        // Default radius should not be part of the url
        if (radius >= 0 && radius !== 20) {
            urlParams.radius = radius;
        }
        if (searchRequest.focusResults) {
            urlParams.focusResults = true;
        }

        return urlWithParams(url, urlParams);
    }

    sendGa4Tracking({ keywords, locations, radius }) {
        if (keywords || locations) {
            let keywordsLowercase = null;

            if (keywords) {
                if (keywords.length) {
                    keywordsLowercase = keywords.toLowerCase();
                }
            }

            let locationsLowercase = null;

            if (locations) {
                if (locations.length) {
                    locationsLowercase = locations.toLowerCase();
                }
            }

            trackGa4({
                event: 'search',
                keywords: keywordsLowercase,
                locations: locationsLowercase,
                filters: radius.length ? 'radius' : null,
                form_type: 'Job Search',
            });
        }
    }
}

app.boot(IndexIndex);

if (module.hot) {
    module.hot.accept(module.children, () => {
        // eslint-disable-next-line no-console
        console.log('Rebooting controller due to HMR...');
        app.boot(IndexIndex);
    });

    module.hot.accept(() => {});
}
