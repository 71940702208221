var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg fill=\"none\" viewbox=\"0 0 24 24\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><path d=\"M11.99 4.02A7.98 7.98 0 0 0 4 12.01a7.97 7.97 0 0 0 5.08 7.44c-.08-.64-.14-1.6.04-2.3l.93-3.97s-.26-.47-.26-1.2c0-1.1.64-1.94 1.44-1.94.69 0 1.01.52 1.01 1.12 0 .69-.45 1.7-.65 2.67-.18.79.4 1.44 1.19 1.44 1.42 0 2.5-1.49 2.5-3.65 0-1.91-1.37-3.25-3.32-3.25-2.27 0-3.61 1.7-3.61 3.47 0 .69.26 1.43.6 1.82.05.08.07.15.05.23l-.23.91c-.03.15-.12.19-.27.1-.99-.46-1.62-1.93-1.62-3.1 0-2.53 1.82-4.84 5.28-4.84 2.77 0 4.93 1.98 4.93 4.62 0 2.63-1.74 4.97-4.13 4.97-.8 0-1.57-.42-1.82-.91l-.5 1.9a9.1 9.1 0 0 1-.99 2.1A7.99 7.99 0 0 0 20 12a8.01 8.01 0 0 0-8-7.99l-.01.01z\" fill=\"currentColor\"/></svg>";
},"useData":true});