var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg fill=\"none\" viewbox=\"0 0 24 24\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><path d=\"M12.11 19.49h-.2a3.4 3.4 0 0 1-2.14-.86c-.41-.29-.8-.56-1.23-.63a4.13 4.13 0 0 0-.66-.06 4.04 4.04 0 0 0-.9.1c-.14.04-.25.05-.34.05-.1 0-.2 0-.24-.16l-.1-.36c-.05-.3-.1-.5-.24-.52-1.5-.22-1.93-.55-2.03-.77 0-.04 0-.06-.03-.1a.19.19 0 0 1 .14-.18c2.32-.38 3.35-2.73 3.39-2.84.14-.3.16-.55.08-.76-.15-.38-1.17-.66-1.23-.68-.7-.27-.75-.55-.72-.68.05-.25.37-.41.63-.41a.41.41 0 0 1 .18.03c.3.14.58.21.82.21.34 0 .47-.14.5-.15l-.04-.48c-.08-1.09-.15-2.45.2-3.22a4.18 4.18 0 0 1 3.88-2.5h.34c.66 0 2.85.18 3.89 2.49.35.77.26 2.12.2 3.22v.05c-.01.15-.01.3-.04.44a.7.7 0 0 0 .45.15c.23 0 .5-.07.77-.2.1-.05.2-.05.25-.05.1 0 .2 0 .27.04.23.07.39.25.39.41 0 .17-.12.4-.72.64-.06.03-.14.04-.23.07-.34.1-.86.27-1.02.63-.1.2-.06.46.08.75.05.12 1.08 2.47 3.4 2.86.1.01.15.1.14.19 0 .03-.01.06-.04.1-.09.21-.51.52-2.02.76-.12.02-.17.2-.25.52l-.1.36c-.03.1-.1.17-.2.17h-.02c-.08 0-.2-.01-.34-.05a4.75 4.75 0 0 0-.9-.1c-.21 0-.42.02-.66.07-.45.07-.84.35-1.23.62-.57.4-1.18.83-2.13.83z\" fill=\"currentColor\"/></svg>";
},"useData":true});